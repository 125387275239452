<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { listPayDetailAPI, listPayDetailSummary } from "@/api/vip/report/payQuery"; //会员支付查询接口

export default {
  name: "payQuery",
  components: { TablePage },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "会员支付查询",
        rowKey: "id",
        search: [
          {
            label: "业务时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "会员级别/会员卡号/会员名称/小票号/收银员名称/门店编码/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "vipLevelNos", label: "级别编码" },
              { filter: "vipLevelNames", label: "级别名称" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" },
              { filter: "billNos", label: "小票号" },
              { filter: "cashierNames", label: "收银员" },
              { filter: "shopNos", label: "门店编码" },
              { filter: "shopNames", label: "门店名称" },
            ],
          },
          this.$select({
            key: "viplevel",
            option: {
              filter: "vipLevelIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "vip",
            option: {
              filter: "vipIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          {
            type: "input",
            label: "小票号",
            model: "",
            filter: "billNo",
            seniorSearch: true,
          },
          this.$select({
            key: "listMachine",
            option: {
              filter: "machineIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          {
            type: "local",
            label: "付款类型",
            model: "",
            filter: "payTypes",
            seniorSearch: true,
            option: {
              clearable: true,
              data: [
                { label: "门店销售支付", value: 1 },
                { label: "门店预订支付", value: 2 },
                { label: "线上商城支付", value: 3 },
                { label: "门店换卡扣减", value: 4 },
              ],
              label: "label",
              value: "value",
              multiple: true,
            },
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabsColumns: [
          {
            title: "会员支付明细查询",
            type: "vipPayList",
            getListApi: listPayDetailAPI,
            exportOption: {
              ossKeyUrl: "api/system/vip/report/Pay/query/getListPayDetailExport",
              fastExportUrl: "api/system/vip/report/Pay/query/listPayDetailExport",
              exportName: "会员支付明细",
            },
            defaultBody: { summaryType: 1 },
            getSummaryApi: listPayDetailSummary,
            uuid: "3409D386-1F1A-D1B8-38AA-2548BDB75742",
            columns: [
              {
                prop: "payDate",
                label: "日期",
                minWidth: 155,
              },
              {
                prop: "billNo",
                label: "小票号",
                minWidth: 180,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "machineNo",
                label: "收银机编号",
                minWidth: 150,
              },
              {
                prop: "machineName",
                label: "收银机名称",
                minWidth: 150,
              },
              {
                prop: "cashierNo",
                label: "收银员编号",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员名称",
                minWidth: 150,
              },
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 150,
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 150,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "tel",
                label: "手机号",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "payTypeName",
                label: "付款类型",
                minWidth: 120,
              },
              {
                prop: "payFillMoney",
                label: "扣除可用充值",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "payLargessMoney",
                label: "扣除可用赠送",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "payGainMoney",
                label: "扣除可用返现",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "付款总额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "endMoney",
                label: "付款后卡余额",
                minWidth: 130,
                sortable: true,
              },
            ],
            summary: [
              "payMoney",
              "payFillMoney",
              "payLargessMoney",
              "payGainMoney",
              "endMoney",
            ],
          },
          {
            title: "会员支付查询（按会员卡汇总）",
            type: "vipPaySummaryList",
            getListApi: listPayDetailAPI,
            exportOption: {
              ossKeyUrl: "api/system/vip/report/Pay/query/getListPayDetailExport",
              fastExportUrl: "api/system/vip/report/Pay/query/listPayDetailExport",
              exportName: "会员支付查询（按会员卡汇总）",
            },
            defaultBody: { summaryType: 2 },
            getSummaryApi: listPayDetailSummary,
            uuid: "BC18482E-069A-BE1A-2EE2-1D4F68A5C140",
            columns: [
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 130,
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 130,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 130,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 130,
              },
              {
                prop: "payFillMoney",
                label: "扣除可用充值",
                minWidth: 110,
                sortable: true,
              },
              {
                prop: "payLargessMoney",
                label: "扣除可用赠送",
                minWidth: 110,
                sortable: true,
              },
              {
                prop: "payGainMoney",
                label: "扣除可用返现",
                minWidth: 110,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "付款总额",
                minWidth: 110,
                sortable: true,
              },
            ],
            summary: ["payMoney", "payFillMoney", "payLargessMoney", "payGainMoney"],
          },
          {
            title: "会员支付查询（按小票汇总）",
            type: "billNo",
            getListApi: listPayDetailAPI,
            exportOption: {
              ossKeyUrl: "api/system/vip/report/Pay/query/getListPayDetailExport",
              fastExportUrl: "api/system/vip/report/Pay/query/listPayDetailExport",
              exportName: "会员支付查询（按小票汇总）",
            },
            defaultBody: { summaryType: 3 },
            getSummaryApi: listPayDetailSummary,
            uuid: "15CD10E3-8FB1-3A7B-147F-C94B527E11C3",
            columns: [
              {
                prop: "payDate",
                label: "日期",
                minWidth: 155,
              },
              {
                prop: "billNo",
                label: "小票号/订单号",
                minWidth: 180,
              },
              {
                prop: "billNo",
                label: "内部单号",
                minWidth: 180,
              },
              {
                prop: "shopName",
                label: "门店",
                minWidth: 150,
              },
              {
                prop: "machineName",
                label: "收银机",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "payTypeName",
                label: "付款类型",
                minWidth: 100,
              },
              {
                prop: "payFillMoney",
                label: "扣除可用充值",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "payLargessMoney",
                label: "扣除可用赠送",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "payGainMoney",
                label: "扣除可用返现",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "付款总额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["payMoney", "payFillMoney", "payLargessMoney", "payGainMoney"],
          },
          {
            title: "会员支付查询（按门店汇总）",
            type: "shop",
            getListApi: listPayDetailAPI,
            exportOption: {
              ossKeyUrl: "api/system/vip/report/Pay/query/getListPayDetailExport",
              fastExportUrl: "api/system/vip/report/Pay/query/listPayDetailExport",
              exportName: "会员支付查询（按门店汇总）",
            },
            defaultBody: { summaryType: 4 },
            getSummaryApi: listPayDetailSummary,
            uuid: "F78AC82B-3E6A-718B-A525-5CBE203A546D",
            columns: [
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "payFillMoney",
                label: "扣除可用充值",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "payLargessMoney",
                label: "扣除可用赠送",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "payGainMoney",
                label: "扣除可用返现",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "付款总额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["payMoney", "payFillMoney", "payLargessMoney", "payGainMoney"],
          },
          {
            title: "会员支付查询（按日期汇总）",
            type: "date",
            getListApi: listPayDetailAPI,
            exportOption: {
              ossKeyUrl: "api/system/vip/report/Pay/query/getListPayDetailExport",
              fastExportUrl: "api/system/vip/report/Pay/query/listPayDetailExport",
              exportName: "会员支付查询（按日期汇总）",
            },
            defaultBody: { summaryType: 5 },
            getSummaryApi: listPayDetailSummary,
            uuid: "a4143040-f757-99b0-4f16-c624d118f08d",
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 155,
              },
              {
                prop: "payFillMoney",
                label: "扣除可用充值",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "payLargessMoney",
                label: "扣除可用赠送",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "payGainMoney",
                label: "扣除可用返现",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "付款总额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["payMoney", "payFillMoney", "payLargessMoney", "payGainMoney"],
          },
          {
            title: "会员支付查询（按会员级别汇总）",
            type: "vipLevel",
            getListApi: listPayDetailAPI,
            exportOption: {
              ossKeyUrl: "api/system/vip/report/Pay/query/getListPayDetailExport",
              fastExportUrl: "api/system/vip/report/Pay/query/listPayDetailExport",
              exportName: "会员支付查询（按会员级别汇总）",
            },
            defaultBody: { summaryType: 6 },
            getSummaryApi: listPayDetailSummary,
            uuid: "eabc8375-522a-cf5f-c73a-449b9a3c6e5a",
            columns: [
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 120,
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 120,
              },
              {
                prop: "payFillMoney",
                label: "扣除可用充值",
                minWidth: 110,
                sortable: true,
              },
              {
                prop: "payLargessMoney",
                label: "扣除可用赠送",
                minWidth: 110,
                sortable: true,
              },
              {
                prop: "payGainMoney",
                label: "扣除可用返现",
                minWidth: 110,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "付款总额",
                minWidth: 110,
                sortable: true,
              },
            ],
            summary: ["payMoney", "payFillMoney", "payLargessMoney", "payGainMoney"],
          },
        ],
      },
    };
  },
};
</script>
